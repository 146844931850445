import React from "react";
import "./Terms.css"; // Import the CSS file

const Terms = () => {
  return (
    <div className="terms-container">
      <h2>Terms & Conditions</h2>
      <p><strong>Last Updated:</strong> [28th March, 2025]</p>

      <p>By accessing <a href="https://www.dietwithsweta.com" target="_blank" rel="noopener noreferrer">www.dietwithsweta.com</a> ("Website"), you agree to comply with these Terms & Conditions.</p>

      <h3>1. Use of Services</h3>
      <ul>
        <li>Our services include diet plans, consultations, and wellness advice.</li>
        <li>You must provide accurate health information for personalized plans.</li>
        <li>Not a substitute for medical advice; consult a doctor before starting any diet.</li>
      </ul>

      <h3>2. Account & Payments</h3>
      <ul>
        <li>You must create an account for personalized services.</li>
        <li>Payments are non-refundable unless stated otherwise in our Refund Policy.</li>
      </ul>

      <h3>3. Intellectual Property</h3>
      <ul>
        <li>All content (diet plans, articles, videos) is owned by DietwithSweta.</li>
        <li>Unauthorized use, copying, or distribution is prohibited.</li>
      </ul>

      <h3>4. User Responsibilities</h3>
      <ul>
        <li>Do not misuse the website (e.g., hacking, spamming).</li>
        <li>Provide truthful health information.</li>
      </ul>

      <h3>5. Limitation of Liability</h3>
      <ul>
        <li>We are not liable for any health issues arising from following our advice.</li>
        <li>Website downtime or technical errors.</li>
      </ul>

      <h3>6. Changes to Terms</h3>
      <p>We may modify these terms at any time. Continued use constitutes acceptance.</p>

      <h3>7. Governing Law</h3>
      <p>These terms are governed by the laws of West Bengal.</p>

      <h3>8. Contact Us</h3>
      <p>For questions, email us at <a href="mailto:dietwithsweta@gmail.com">dietwithsweta@gmail.com</a>.</p>

      <hr />

    </div>
  );
};

export default Terms;

import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import BlogHeader from '../components/Blog/BlogHeader';
import BlogSearch from '../components/Blog/BlogSearch';
import NewsletterSubscription from '../components/Blog/NewsletterSubscription';
import LatestPostsSection from '../components/Blog/Posts/LatestPostsSection';
import BlogPost from './BlogPost';
import FirstStep from '../components/About/FirstStep';

const Blog = () => {
  return (
    <>
    <BlogHeader />
    {/* <BlogSearch /> */}
    <Routes>
      <Route path="/" element={<LatestPostsSection />} />
      <Route path="/post/:id" element={<BlogPost />} />
    </Routes>
    <NewsletterSubscription />
    <FirstStep />
  </>
  );
};

export default Blog;

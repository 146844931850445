import React from 'react';
import { useLocation } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import './PaymentPage.css';
import { contactInfo } from '../../components/constants';
import { Paragraph, Title } from '../../components/ReusableComponents/ReusableComponent';

const PaymentPage = () => {
  const { state } = useLocation();
  const {
    planTitle,
    // duration,
    totalPrice,
    phoneNumber,
    description,
    features,
    image
  } = state || {};

  // if (!planTitle || !totalPrice) {
  //   return <p>No payment details available</p>;
  // }

  // Extract username from email
  const email = contactInfo.email;
  const username = email.split('@')[0];
  
  // UPI ID from contactInfo
  const upiId = `${username}${contactInfo.upi_end}`;

  return (
    <div className="payment-container">
      <div className="left-section">
      <div className="image-container">
    <img 
      src="./GooglePayLogo.png" 
      alt="Top Image" 
      className="top-image" 
    />
  </div>
        <div className="qr-code">
          <QRCodeSVG
            value={`upi://pay?pa=${upiId}&pn=${planTitle}&mc=&tid=&am=${totalPrice}&cu=INR`}
            size={300}
          />
        </div>
      </div>
      <div className="right-section">
        <div className="plan-details">
          <div className="instructions">
            <Paragraph>
              Scan the QR code to make a payment, and send us a screenshot via Instagram DM once done.
            </Paragraph>
            <Paragraph>
              For bank details, contact us on WhatsApp. Please share payment confirmation once the transaction is completed.
            </Paragraph>
            {/* <Paragraph>
              Google Pay, PhonePe, Paytm<br />
              Phone No: {contactInfo.phone}
            </Paragraph> */}
          </div>
          <Title style={{ marginTop: '10px' }}>{planTitle}</Title>
          <Paragraph>
            {features && features.length > 0 ? (
              features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))
            ) : (
              <Paragraph  style={{ marginBottom: '10px' }}>No features listed.</Paragraph>
            )}
          </Paragraph>
          {/* <Paragraph  style={{ marginTop: '10px' }}>Duration: {duration}</Paragraph> */}
          <Paragraph  style={{ marginTop: '10px' }}>Total Price: {totalPrice}</Paragraph>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;

import React from "react";
import "./Terms.css"; // Import the CSS file


const RefundPolicy = () => {
  return (
    <div className="terms-container">
      <h2>Refund & Return Policy</h2>
      <p><strong>Last Updated:</strong> [28th March, 2025]</p>

      <p>At <strong>DietwithSweta</strong>, we strive for customer satisfaction. Please review our refund policy before making a purchase.</p>

      <h3>1. Digital Products & Services</h3>
      <ul>
        <li><strong>Diet Plans & E-books:</strong> Non-refundable once downloaded.</li>
        <li><strong>Online Consultations:</strong> Refundable if canceled at least 24 hours before the scheduled session.</li>
      </ul>

      <h3>2. Subscription Plans</h3>
      <ul>
        <li>Monthly and yearly subscriptions can be canceled anytime.</li>
        <li>No refunds will be issued for unused periods after cancellation.</li>
      </ul>

      <h3>3. Processing Refunds</h3>
      <ul>
        <li>Approved refunds will be processed within <strong>7-10 business days</strong>.</li>
        <li>Refunds will be issued to the <strong>original payment method</strong>.</li>
      </ul>

      <h3>4. Exceptions</h3>
      <ul>
        <li><strong>Technical errors</strong> (e.g., double charges, failed transactions).</li>
        <li><strong>Unfulfilled promises</strong> (e.g., undelivered services).</li>
      </ul>

      <h3>5. How to Request a Refund</h3>
      <p>To request a refund, please email <strong>dietwithsweta@gmail.com</strong> with:</p>
      <ul>
        <li>Your <strong>order details</strong>.</li>
        <li>A <strong>brief explanation</strong> of the reason for the refund request.</li>
      </ul>

      <h3>6. Contact Us</h3>
      <p>For refund-related queries, please contact us at <strong>dietwithsweta@gmail.com</strong>.</p>
    </div>
  );
};

export default RefundPolicy;

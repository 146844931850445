import React from 'react';
import './Topbar.css'; // Import the CSS file for styling

const Topbar = () => {
    return (
        <div className="topbar">
            {/* <div className="contact-info">
                <span className="email">Email: contact@example.com</span>
                <span className="phone">Phone: +123 456 7890</span>
            </div> */}
        </div>
    );
};

export default Topbar;

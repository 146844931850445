// ValuesSection.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHandshake, 
  faHeadset, 
  faComments
} from '@fortawesome/free-solid-svg-icons';
import './ValuesSection.css'; // Import the CSS file
import { Paragraph, Subtitle, Title } from '../ReusableComponents/ReusableComponent';

const values = [
  { 
    title: 'Commitment', 
    icon: faHandshake, 
    description: 'We are dedicated to delivering exceptional service and support, ensuring your success is our top priority.' 
  },
  { 
    title: 'Customer First', 
    icon: faHeadset, 
    description: 'Our customers are at the heart of everything we do, and their satisfaction drives our success.' 
  },
  { 
    title: 'Communication', 
    icon: faComments, 
    description: 'We believe in open, honest, and transparent communication with our clients and within our team.' 
  },
  // { 
  //   title: 'Innovation', 
  //   icon: faLightbulb, 
  //   description: 'We foster a culture of creativity and innovation, continuously seeking new ways to improve our services.' 
  // },
  // { 
  //   title: 'Empathy', 
  //   icon: faHeart, 
  //   description: 'We understand and share the feelings of our clients, striving to provide personalized and compassionate service.' 
  // },
];

const ValuesSection = () => {
  return (
    <section className="values-section">
      <div className="values-header">
        <Title>The Values Behind Our Work</Title>
        <Paragraph>
          Our core values guide everything we do, ensuring we deliver the best possible service to our clients.
        </Paragraph>
      </div>
      <div className="values-grid">
        {values.map((value, index) => (
          <div key={index} className="value-card" style={{ '--animation-order': index + 1 }}>
            <div className="icon" aria-label={value.title}>
              <FontAwesomeIcon icon={value.icon} />
            </div>
            <Subtitle>{value.title}</Subtitle>
            <Paragraph>{value.description}</Paragraph>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ValuesSection;

import React from "react";
import "./Terms.css"; // Import the CSS file


const PrivacyPolicy = () => {
  return (
    <div className="terms-container">
      <h2>Privacy Policy</h2>
      <p><strong>Last Updated:</strong> [28th March, 2025]</p>

      <p>Welcome to <strong>DietwithSweta</strong> ("we," "us," or "our"). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you visit our website <a href="https://www.dietwithsweta.com" target="_blank" rel="noopener noreferrer">www.dietwithsweta.com</a> or use our services.</p>

      <h3>1. Information We Collect</h3>
      <ul>
        <li><strong>Personal Information:</strong> Name, email address, phone number, payment details, and health-related data (if provided for consultation).</li>
        <li><strong>Non-Personal Information:</strong> Browser type, IP address, cookies, and usage data for analytics.</li>
      </ul>

      <h3>2. How We Use Your Information</h3>
      <ul>
        <li>Provide personalized diet plans and consultations.</li>
        <li>Process payments and manage subscriptions.</li>
        <li>Improve our website and services.</li>
        <li>Send promotional emails (you can opt out anytime).</li>
      </ul>

      <h3>3. Data Security</h3>
      <p>We implement industry-standard security measures to protect your data. However, no online transmission is 100% secure, so we cannot guarantee absolute security.</p>

      <h3>4. Sharing of Information</h3>
      <ul>
        <li>We do not sell or share your personal data with third parties, except:</li>
        <li>With your consent.</li>
        <li>For legal compliance or fraud prevention.</li>
        <li>With trusted service providers (e.g., payment processors).</li>
      </ul>

      <h3>5. Cookies & Tracking</h3>
      <p>We use cookies to enhance user experience. You can disable cookies in your browser settings.</p>

      <h3>6. Your Rights</h3>
      <ul>
        <li>Access, update, or delete your data.</li>
        <li>Opt out of marketing communications.</li>
        <li>Request data portability.</li>
      </ul>

      <h3>7. Changes to This Policy</h3>
      <p>We may update this policy periodically. Check this page for the latest version.</p>

      <h3>8. Contact Us</h3>
      <p>For privacy-related concerns, email us at <a href="mailto:dietwithsweta@gmail.com">dietwithsweta@gmail.com</a>.</p>
    </div>
  );
};

export default PrivacyPolicy;
